<template>
  <div class="content-14__banner__img fn-mb-16">
    <img :src="lotteryImage" alt="找不到图片！">
    <div class="c14-alink">
      <span class="c14-alink__text">{{ lotteryTitle }}</span>
      <a
        class="c14-alink__link"
        :href="`/buyplan/${lotteryCode}`"
        @click.prevent="goto({ lotteryCode, link: `/buyplan/${lotteryCode}`})"
      >购彩计划</a>
      <a
        class="c14-alink__link"
        :href="`/shjh/${lotteryCode}`"
        @click.prevent="goto({ lotteryCode, link: `/shjh/${lotteryCode}`})"
      >稳赢杀号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerBox',
  props: {
    lotteryTitle: {
      type: String,
      required: true
    },
    lotteryImage: {
      type: String,
      default: ''
    },
    lotteryCode: {
      type: String,
      required: true
    }
  },
  methods: {
    goto(item) {
      this.$router.push(item.link)
    }
  }
}
</script>

<style lang="scss" scoped>
.fn-mb-16 {
  margin-bottom: 16Px;
}
</style>
