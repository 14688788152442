<template>
  <div v-if="currentImage" class="content-7__videobox">
    <div class="videobox">
      <img :src="currentImage" alt="">
      <div class="videobox__mask">
        <img class="videobox__mask__img" src="@/assets/desktop/img/video-mask.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import VideoImage11x5 from '@/assets/frontend/video/11x5.png'
import VideoImageCqnc from '@/assets/frontend/video/cqnc.png'
import VideoImageFc3d from '@/assets/frontend/video/fc3d.png'
import VideoImageFt from '@/assets/frontend/video/ft.png'
import VideoImageK3 from '@/assets/frontend/video/k3.png'
import VideoImageKlsf from '@/assets/frontend/video/klsf.png'
import VideoImageLhc from '@/assets/frontend/video/lhc.png'
import VideoImagePcEgg from '@/assets/frontend/video/pcEgg.png'
import VideoImagePk10 from '@/assets/frontend/video/pk10.png'
import VideoImageSsc from '@/assets/frontend/video/ssc.png'
import VideoImageTwbg from '@/assets/frontend/video/twbg.png'
export default {
  name: 'VideoContent',
  props: {
    lotteryCode: {
      type: String,
      default: '',
      required: true
    },
    lotteryType: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      currentImage: null
    }
  },
  created() {
    const { lotteryCode, lotteryType } = this
    const videoType = this.getVideoType(lotteryCode, lotteryType)

    switch (videoType) {
      case 'fc3d':
        this.currentImage = VideoImageFc3d
        break
      case 'cqnc':
        this.currentImage = VideoImageCqnc
        break
      case 'twbg':
        this.currentImage = VideoImageTwbg
        break
      case 'ft':
        this.currentImage = VideoImageFt
        break
      case '11x5':
        this.currentImage = VideoImage11x5
        break
      case 'k3':
        this.currentImage = VideoImageK3
        break
      case 'pk10':
        this.currentImage = VideoImagePk10
        break
      case 'ssc':
        this.currentImage = VideoImageSsc
        break
      case 'lhc':
        this.currentImage = VideoImageLhc
        break
      case 'klsf':
        this.currentImage = VideoImageKlsf
        break
      case 'pcEgg':
        this.currentImage = VideoImagePcEgg
        break

      default:
        this.currentImage = null
    }
  },
  methods: {
    getVideoType(lotteryCode, lotteryType) {
      switch (lotteryCode) {
        case 'fc3d':
        case 'pl3':
        case 'shssc':
          return 'fc3d'
        case 'cqxync':
          return 'cqnc'
        case 'twbingo':
          return 'twbg'
        case 'malxyft':
          return 'ft'
      }
      switch (lotteryType) {
        case '11x5':
        case 'k3':
        case 'pk10':
        case 'ssc':
          return lotteryType
        case 'hk6':
          return 'lhc'
        case 'kl10':
          return 'klsf'
        case 'xy28':
          return 'pcEgg'
        case 'kl8': // 暫無
          return 'twbg'
      }

      // 以下沒有直播
      // fcssq: 福彩雙色球
      // pl5: 排列5
      // qlc: 七乐彩
      // qxc: 七星彩
      // cjdlt: 超级大乐透

      console.warn('VideContent: getVideoType: 彩種對影影片類型失敗!', lotteryCode, lotteryType)
      return null
    }
  }
}
</script>
