<template>
  <div v-if="routeName === 'transfer' || routeName === 'transfer-toolbox-code'" class="ball size-middle circle" :class="color">
    <span class="ball__number">{{ number }}</span>
  </div>
  <div v-else class="ball size-large circle" :class="color">
    <span class="ball__number">{{ number }}</span>
  </div>
</template>

<script>
export default {
  name: 'Ball',
  props: {
    number: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      routeName: this.$route.name
    }
  },
  computed: {
    color() {
      const num = this.number.toString()

      switch (num) {
        case '1':
        case '2':
        case '7':
        case '8':
        case '12':
        case '13':
        case '18':
        case '19':
        case '23':
        case '24':
        case '29':
        case '30':
        case '34':
        case '35':
        case '40':
        case '45':
        case '46':
          return 'color--red'
        case '3':
        case '4':
        case '9':
        case '10':
        case '14':
        case '15':
        case '20':
        case '25':
        case '26':
        case '31':
        case '36':
        case '37':
        case '41':
        case '42':
        case '47':
        case '48':
          return 'color--blue'
        case '5':
        case '6':
        case '11':
        case '16':
        case '17':
        case '21':
        case '22':
        case '27':
        case '28':
        case '32':
        case '33':
        case '38':
        case '39':
        case '43':
        case '44':
        case '49':
          return 'color--green'
        default:
          return 'gray'
      }
    }
  },
  methods: {
    transformNumber(num) {
      if (num.length < 2) {
        return '0' + num
      } else {
        return num
      }
    }
  }
}
</script>
