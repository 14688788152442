<template>
  <div v-swiper:mySwiper="swiperOption">
    <div class="swiper-wrapper">
      <a v-for="(banner, index) in banners" :key="index" class="swiper-slide" @click="handleBannerClick(banner.url)">
        <img :src="banner.imgpath" alt="banner" style="width: 100%; height: 280px;">
      </a>
    </div>
    <div slot="pagination" class="swiper-pagination"></div>
  </div>
</template>

<script>
export default {
  name: 'VueSwiper',
  props: {
    banners: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 2000
        },
        speed: 500,
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  created() {
    if (this.banners.length < 2) {
      this.swiperOption.loop = false
    }
  },
  methods: {
    handleBannerClick(url) {
      url && window.open(url, '_blank')
    }
  }
}
</script>
