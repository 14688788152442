<template>
  <div class="c14-card">
    <div class="c14-card__title">
      <img class="c14-card__title__img" :src="lottery.data.iconURL" alt>
      <span class="c14-card__title__text">{{ lottery.data.name }}</span>
    </div>
    <div v-if="codeMapMenu" class="c14-card__content">
      <div class="c14-card__content__titlename">
        <img class="c14-card__content__titlename__img" src="@/assets/desktop/img/c14-1.svg" alt>
        <span class="c14-titlename">走势</span>
      </div>
      <div class="c14-card__content__alinkbox">
        <a
          v-for="menuItem in codeMapMenu.trend"
          :key="menuItem.key"
          class="button-primary fn-customed-padding"
          :href="`/lottery/trend/${lottery.lotteryCode}?trendType=${menuItem.key}`"
          @click.prevent="goto({lotteryCode: lottery.lotteryCode,
                                link:`/lottery/trend/${lottery.lotteryCode}?trendType=${menuItem.key}`})"
        >
          <span class="button-primary__text">{{ menuItem.title }}</span>
        </a>
      </div>
      <div class="c14-card__content__titlename">
        <img class="c14-card__content__titlename__img" src="@/assets/desktop/img/c14-2.svg" alt>
        <span class="c14-titlename">统计</span>
      </div>
      <div class="c14-card__content__alinkbox">
        <a
          v-for="menuItem in codeMapMenu.stats"
          :key="menuItem.key"
          class="button-primary fn-customed-padding"
          :href="`/lottery/trend/${lottery.lotteryCode}?trendType=${menuItem.key}`"
          @click.prevent="goto({lotteryCode: lottery.lotteryCode,
                                link:`/lottery/trend/${lottery.lotteryCode}?trendType=${menuItem.key}`})"
        >
          <span class="button-primary__text">{{ menuItem.title }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalysisCard',
  props: {
    lottery: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lotteryType: '',
      analysisMenus: {
        pk10: {
          trend: [
            { title: '冠亚和走势', key: 'GuanYaHeZouShih' },
            { title: '基本走势', key: 'JiBenZouShih' },
            { title: '定位走势', key: 'DingWeiZouShih' }
          ],
          stats: [
            { title: '冷热分析', key: 'LengReFenXi' },
            { title: '两面统计', key: 'LiangMianTongJi' },
            { title: '每日长龙统计', key: 'MeiRihChangLongTongJi' }
          ]
        },
        ssc: {
          trend: [
            { title: '基本走势', key: 'JiBenZouShih' },
            { title: '龙虎走势', key: 'LongHuZouShih' },
            { title: '形态走势', key: 'XingTaiZouShih' }
          ],
          stats: [
            { title: '冷热分析', key: 'LengReFenXi' },
            { title: '今日号码统计', key: 'JinRiHaoMaTongJi' },
            { title: '每日长龙统计', key: 'MeiRihChangLongTongJi' }
          ]
        },
        k3: {
          trend: [
            { title: '定位走势', key: 'DingWeiZouShih' },
            { title: '和值走势', key: 'HeJhihZouShih' },
            { title: '大小走势', key: 'DaXiaoZouShih' }
          ],
          stats: [
            { title: '历史号码统计', key: 'LiShiHaoMaTongJi' },
            { title: '每日长龙统计', key: 'MeiRihChangLongTongJi' }
          ]
        },
        '11x5': {
          trend: [
            { title: '定位走势', key: 'DingWeiZouShih' },
            { title: '号码分布', key: 'HaoMaFenBu' },
            { title: '龙虎走势', key: 'LongHuZouShih' }
          ],
          stats: [
            { title: '冷热分析', key: 'LengReFenXi' },
            { title: '今日号码统计', key: 'JinRiHaoMaTongJi' },
            { title: '每日长龙统计', key: 'MeiRihChangLongTongJi' }
          ]
        }
      }
    }
  },
  computed: {
    codeMapMenu() {
      return this.analysisMenus[this.lotteryType]
    }
  },
  async created() {
    this.lotteryType = await this.$getLotteryType(this.lottery.lotteryCode)
  },
  methods: {
    goto(item) {
      this.$router.push(item.link)
    }
  }
}
</script>

<style lang="scss" scoped>
.fn-customed-padding {
  padding: .5rem .75rem;
}
</style>
