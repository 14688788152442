<template>
  <div class="content-3">
    <div class="content-3__menu">
      <div class="menubar">
        <template v-for="item in menuItems">
          <div :key="item.name" class="menubar__item" :class="{active: item.active}" @click="changeMenuItemList(item)">
            <span class="menubar__item__text">{{ item.name }}</span>
            <div class="menubar__item__lightborder"></div>
          </div>
        </template>
      </div>
    </div>
    <div class="content-3__content">
      <template v-for="item in displayLotteryList">
        <LotteryItem :key="item.lotteryCode" :lottery-detail="item.data" :lottery-code="item.lotteryCode" />
      </template>
    </div>
  </div>
</template>

<script>
import LotteryItem from '@/components/web/pages/index/LotteryItem'
export default {
  name: 'Menu',
  components: {
    LotteryItem
  },
  props: {
    homePageLotteries: {
      type: Object,
      required: true,
      default: () => {
        console.error('Menu required key "homePageLotteries"')
        return {}
      }
    }
  },
  data() {
    return {
      menuItems: [
        {
          name: '热门彩',
          lottery: 'hotLottery',
          active: false
        },
        {
          name: 'PK10',
          lottery: 'pk10Lottery',
          active: false
        },
        {
          name: '快3',
          lottery: 'k3Lottery',
          active: false
        },
        {
          name: '11选5',
          lottery: 'syx5Lottery',
          active: false
        },
        {
          name: '时时彩',
          lottery: 'sscLottery',
          active: false
        },
        {
          name: '幸运28',
          lottery: 'xy28Lottery',
          active: false
        },
        {
          name: '快乐8',
          lottery: 'kl8Lottery',
          active: false
        },
        {
          name: '快乐十分',
          lottery: 'kl10Lottery',
          active: false
        },
        {
          name: '一般',
          lottery: 'othorLottery',
          active: false
        }
      ],
      displayLotteryList: []
    }
  },
  created() {
    this.getMenuItemList()
    this.menuItems[0].active = true
  },
  methods: {
    changeMenuItemList(item) {
      this.displayLotteryList = []
      this.menuItems.forEach(item => {
        item.active = false
      })
      item.active = true
      const selectedlotterylist = this.homePageLotteries[item.lottery]
      this.getMenuItem(selectedlotterylist)
    },
    getMenuItemList() {
      const selectedlotterylist = this.homePageLotteries['hotLottery']
      this.getMenuItem(selectedlotterylist)
    },
    async getMenuItem(selectedlotterylist = []) {
      const [publicCpMap, error] = await this.$store.dispatch('lottery/getLotteryMap')
      if (error) return
      // 特殊處理: 儲存在siteSetting 裡的hotLottery 並不會隨著publicCp 的刪減做過濾
      // 所以由前端處理這一手、將publicCp 和hotLottery 做交集
      selectedlotterylist = selectedlotterylist.filter((lotteryCode) => publicCpMap[lotteryCode])
      selectedlotterylist.forEach(lottery => {
        const obj = {}
        obj.data = publicCpMap[lottery]
        obj.lotteryCode = lottery
        this.displayLotteryList.push(obj)
      })
    }
  }
}
</script>

<style scoped>
  .menu__item {
    cursor: pointer
  }
</style>
