<template>
  <div v-if="Object.keys(drawInfo).length > 0" class="content-3__content__item">
    <div class="lottery-card">
      <div class="lottery-card__content">
        <a @click="goto({ link: `/lottery/trend/${lotteryCode}`, lotteryCode: lotteryCode })">
          <div class="lottery-card__content__icon">
            <img class="lottery-img" :src="lotteryDetail.iconURL" alt="彩种icon">
          </div>
        </a>
        <div class="lottery-card__content__title">
          <div class="lotterybox">
            <div class="lotterybox__lottery-name">
              <span class="lotterybox__lottery-name__text">{{ lotteryDetail.name }}</span>
            </div>
            <div class="lotterybox__lottery-datenumber">
              <span class="lotterybox__lottery-datenumber__number" v-text="periodLabel"></span>
              <span class="lotterybox__lottery-datenumber__text">期</span>
            </div>
            <div class="lotterybox__lottery-detail">
              <span v-if="lotteryDetail.code === 'smam6'" class="lotterybox__lottery-detail__text">开奖频率: 每小时</span>
              <span v-else-if="categoryCode === 'local' || categoryCode === 'china'" class="lotterybox__lottery-detail__text">开奖星期: {{ formatWeek(lotteryDetail.weekdays) }}</span>
              <span v-else-if="categoryCode === 'gaopin' || categoryCode === 'jisu'" class="lotterybox__lottery-detail__text">开奖频率: {{ formatSeconds(lotteryDetail.intervalSeconds) }}分</span>
              <span v-else-if="lotteryDetail.closeDay" class="lotterybox__lottery-detail__text">休市: {{ formatClose(lotteryDetail.closeDay) }}</span>
            </div>
          </div>
          <div class="ballnumber">
            <div v-if="lotteryType === 'pk10'" class="ballbox">
              <template v-for="(ball, index) in get(drawInfo, 'prePeriod.balls', [])">
                <div :key="`ball-${index}`" class="ballbox__item">
                  <div
                    class="ball size-large"
                    :class="{
                      [`ball-0${ball}`]: ball !== 10,
                      'ball-10': ball === 10,
                    }"
                  >
                    <span class="ball__number">{{ ball }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div v-if="lotteryType === 'hk6'" class="ballbox">
              <template v-for="(ball, index) in get(drawInfo, 'prePeriod.balls', [])">
                <div v-if="index === 5" :key="`ball-${index}`" class="ballbox__item">
                  <LotteryBall :number="ball" />
                  <div class="ballbox__item__detail">
                    <span class="detail-text">{{ formatLot(drawInfo.prePeriod.ruleDetail.shengXiao[index]) }}</span>
                    <span class="detail-text">/</span>
                    <span class="detail-text red">{{ formatLot(drawInfo.prePeriod.ruleDetail.wuXing[index]) }}</span>
                  </div>
                </div>
                <div v-if="index === 5" :key="`plus-${index}`" class="ballbox__item">
                  <img src="@/assets/desktop/img/plus.svg" alt="">
                </div>
                <div v-else :key="`ball-${index}`" class="ballbox__item">
                  <LotteryBall :number="ball" />
                  <div class="ballbox__item__detail">
                    <span class="detail-text">{{ formatLot(drawInfo.prePeriod.ruleDetail.shengXiao[index]) }}</span>
                    <span class="detail-text">/</span>
                    <span class="detail-text red">{{ formatLot(drawInfo.prePeriod.ruleDetail.wuXing[index]) }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div v-if="(lotteryType === 'ssc' || lotteryType === '11x5' || lotteryType === 'kl8' || lotteryType === 'kl10') && (lotteryCode !== 'cqxync') && (lotteryCode !== 'cqssc')" class="ballbox">
              <template v-for="(ball, index) in get(drawInfo, 'prePeriod.balls', [])">
                <div :key="`ball-${index}`" class="ballbox__item">
                  <div class="ball color--primary size-large circle">
                    <span class="ball__number">{{ ball }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div v-if="lotteryType === 'k3'" class="ballbox">
              <template v-for="(ball, index) in get(drawInfo, 'prePeriod.balls', [])">
                <div :key="`ball-${index}`" class="ballbox__item">
                  <img :src="require(`@/assets/desktop/img/ball/dice/dice${ball}@1.5x.png`)" alt="">
                </div>
              </template>
            </div>
            <div v-if="lotteryType === 'xy28'" class="ballbox">
              <div class="ball color--primary size-large circle">
                <span class="ball__number">{{ get(drawInfo, 'prePeriod.balls[0]', 0) }}</span>
              </div>
              <div class="ballbox__item">
                <img src="@/assets/desktop/img/plus.svg" alt="">
              </div>
              <div class="ball color--primary size-large circle">
                <span class="ball__number">{{ get(drawInfo, 'prePeriod.balls[1]', 0) }}</span>
              </div>
              <div class="ballbox__item">
                <img src="@/assets/desktop/img/plus.svg" alt="">
              </div>
              <div class="ball color--primary size-large circle">
                <span class="ball__number">{{ get(drawInfo, 'prePeriod.balls[2]', 0) }}</span>
              </div>
              <div class="ballbox__item">
                <img src="@/assets/desktop/img/equal.svg" alt="">
              </div>
              <div class="ball color--red size-large circle">
                <span class="ball__number">{{ get(drawInfo, 'prePeriod.balls[0]', 0) + get(drawInfo, 'prePeriod.balls[1]', 0) + get(drawInfo, 'prePeriod.balls[2]', 0) }}</span>
              </div>
            </div>
            <div v-if="lotteryCode === 'cqxync'" class="ballbox">
              <template v-for="(ball, index) in get(drawInfo, 'prePeriod.balls', [])">
                <div :key="`ball-${index}`" class="ballbox__item">
                  <div class="ball size-large" :class="`haomaimg-${ball}`"></div>
                </div>
              </template>
            </div>
            <div v-if="lotteryCode === 'cqssc'" class="ballbox">
              <template v-for="(ball, index) in get(drawInfo, 'prePeriod.balls', [])">
                <div :key="`ball-${index}`" class="ballbox__item">
                  <div class="ball size-large" :class="`zodiac-${ball}`"></div>
                </div>
              </template>
            </div>
            <div v-if="lotteryType === 'others'" class="ballbox">
              <template v-for="(ball, index) in get(drawInfo, 'prePeriod.balls', [])">
                <div :key="`ball-${index}`" class="ballbox__item">
                  <div class="ball color--red size-large circle">
                    <span class="ball__number">{{ ball }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div class="countdown">
              <CounterDown
                :draw-info="drawInfo"
                :lottery-code="lotteryCode"
                :category-code="categoryCode"
                @changeDrawInfo="changeDrawInfo($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="lottery-card__content__form">
        <table v-if="lotteryType === 'ssc' || lotteryType === '11x5'">
          <thead>
            <tr>
              <th colspan="3"><span>总和</span></th>
              <th><span>1~5龙虎</span></th>
              <th><span>前三</span></th>
              <th><span>中三</span></th>
              <th><span>后三</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>{{ get(drawInfo, 'prePeriod.ruleDetail.zongHe[0]', 0) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDanShuang[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDaXiao[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.longHu', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.singTai[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.singTai[1]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.singTai[2]', '')) }}</span></td>
            </tr>
          </tbody>
        </table>
        <table v-if="lotteryType === 'hk6'">
          <thead>
            <tr>
              <th colspan="4"><span>总和</span></th>
              <th colspan="2"><span>特码</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>{{ get(drawInfo, 'prePeriod.ruleDetail.zongHe[0]', 0) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDanShuang[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDaXiao[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.chiSeBo', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.hmDanShuang[6]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.hmDaXiao[6]', '')) }}</span></td>
            </tr>
          </tbody>
        </table>
        <table v-if="lotteryType === 'k3'">
          <thead>
            <tr>
              <th colspan="3"><span>总和</span></th>
              <th colspan="3"><span>鱼虾蟹</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>{{ get(drawInfo, 'prePeriod.ruleDetail.zongHe[0]', 0) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDanShuang[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDaXiao[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.haiSian[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.haiSian[1]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.haiSian[2]', '')) }}</span></td>
            </tr>
          </tbody>
        </table>
        <table v-if="lotteryType === 'pk10'">
          <thead>
            <tr>
              <th colspan="5"><span>1~5龙虎</span></th>
              <th colspan="3"><span>冠亚和</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zuLongHu[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zuLongHu[1]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zuLongHu[2]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zuLongHu[3]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zuLongHu[4]', '')) }}</span></td>
              <td><span>{{ get(drawInfo, 'prePeriod.ruleDetail.guanYaHe', 0) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.guanYaHeDaXiao[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.guanYaHeDanShuang[0]', '')) }}</span></td>
            </tr>
          </tbody>
        </table>
        <table v-if="lotteryType === 'xy28'">
          <thead>
            <tr>
              <th colspan="2"><span>总和</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDaXiao[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDanShuang[0]', '')) }}</span></td>
            </tr>
          </tbody>
        </table>
        <table v-if="lotteryType === 'kl8'">
          <thead>
            <tr>
              <th colspan="3"><span>总和</span></th>
              <th><span>单双</span></th>
              <th><span>前后</span></th>
              <th><span>五行</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>{{ get(drawInfo, 'prePeriod.ruleDetail.zongHe[0]', 0) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDaXiao[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDanShuang[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.hmDanShuangPan', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.hmDaXiaoPan', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.wuXing[0]', '')) }}</span></td>
            </tr>
          </tbody>
        </table>
        <table v-if="lotteryType === 'kl10'">
          <thead>
            <tr>
              <th colspan="4"><span>总和</span></th>
              <th colspan="4"><span>1~5龙虎</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>{{ get(drawInfo, 'prePeriod.ruleDetail.zongHe[0]', 0) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDaXiao[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDanShuang[0]', '')) }}</span></td>
              <td><span>尾{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDaXiao[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zuLongHu[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zuLongHu[1]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zuLongHu[2]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zuLongHu[3]', '')) }}</span></td>
            </tr>
          </tbody>
        </table>
        <table v-if="lotteryType === 'others'">
          <thead>
            <tr>
              <th colspan="3"><span>总和</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>{{ get(drawInfo, 'prePeriod.ruleDetail.zongHe[0]', 0) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDaXiao[0]', '')) }}</span></td>
              <td><span>{{ formatLot(get(drawInfo, 'prePeriod.ruleDetail.zongHeDanShuang[0]', '')) }}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="lottery-card__content__quickselection">
        <template v-for="(item, index) in filterMenu">
          <a :key="index" class="button-primary" @click="lotteryItemGoto({ link: `/${item.name}/${lotteryCode}`, name: item.name})">
            <span class="button-primary__text">{{ item.label }}</span>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetailDrawInfo_desktop } from '@/api/desktop/basic'
import { ruleCodeMapping } from '@/utils/newRuleCodeMapping-Sean'
import CounterDown from '@/components/web/common/CounterDown'
import LotteryBall from '@/components/web/common/LotteryBall'
import get from 'lodash.get'

const weekMap = ['日', '一', '二', '三', '四', '五', '六']

export default {
  name: 'LotteryItem',
  components: {
    CounterDown,
    LotteryBall
  },
  props: {
    lotteryDetail: {
      type: Object,
      required: true,
      default: () => {
        console.error('LotteryItem required key "LotteryDetail"')
        return {}
      }
    },
    lotteryCode: {
      type: String,
      required: true,
      default: () => {
        console.error('LotteryItem required key "lotteryCode"')
        return ''
      }
    }
  },
  data() {
    return {
      lotteryType: '',
      categoryCode: '',
      drawInfo: {},
      menuList: [
        { label: '开奖视频', name: 'video' },
        { label: '走势图表', name: 'lottery/trend' },
        { label: '两面推荐', name: 'lmtj' },
        { label: '专家推荐', name: 'expert' },
        { label: '购彩计划', name: 'buyplan' },
        { label: '杀号计划', name: 'shjh' }
      ]
    }
  },
  computed: {
    filterMenu() {
      switch (this.lotteryType) {
        case 'ssc':
          return this.menuList.filter(item => item.name !== 'lmtj')
        case 'kl8':
        case 'hk6':
        case 'kl10':
        case 'others':
          return this.menuList.filter(item => item.name === 'video' || item.name === 'lottery/trend')
        case '11x5':
        case 'k3':
          return this.menuList.filter(item => item.name !== 'lmtj' && item.name !== 'lmtj')
        case 'pk10':
          return this.menuList
        case 'xy28':
          return this.menuList.filter(item => item.name === 'video' || item.name === 'lottery/trend' || item.name === 'buyplan')
        default:
          console.error('menuList filter error!', this.lotteryType)
          return []
      }
    },
    periodLabel() {
      return get(this, 'drawInfo.prePeriod.period', '-')
    }
  },
  async created() {
    await this.getLotteryType()
    this.getCategoryCode()
    this.getDetailDrawInfo(this.lotteryCode)
  },
  methods: {
    async lotteryItemGoto(item) {
      if (item.name === 'video') {
        const { lotteryCode } = this
        const [lotteryNameMapping, error] = await this.$store.dispatch('lottery/getLotteryNameMapping')
        if (error) return

        const lotteryName = lotteryNameMapping[lotteryCode]
        this.$store.commit('lottery/OPEN_VIDEO', { lotteryCode, lotteryName })
      } else {
        this.$router.push(item.link)
      }
    },
    goto(item) {
      this.$router.push(item.link)
    },
    async getLotteryType() {
      this.lotteryType = await this.$getLotteryType(this.lotteryCode)
    },
    getCategoryCode() {
      this.categoryCode = this.lotteryDetail.categoryCode
    },
    async getDetailDrawInfo(code) {
      const [data, error] = await getDetailDrawInfo_desktop(code)
      if (error) return

      this.drawInfo = data[code]
    },
    formatWeek(weekArray) {
      return weekArray.length === 7 ? '每日' : weekArray.map(day => weekMap[day]).join('')
    },
    formatSeconds(seconds) {
      return seconds / 60
    },
    formatLot(value) {
      return ruleCodeMapping[value]
    },
    // 格式化休市
    formatClose(val) {
      const strFirst = val.split('\n').map(item => item.split(' ~ ').map(date => date.replace(/^\d{4}\-/, '').replace('-', '/')))
      // 當天日期不顯示區間
      const strNoRepeat = strFirst.map(item => item[0] === item[1] ? [item[0]] : item).map(item => item.join('~'))
      return strNoRepeat.join(' , ')
    },
    changeDrawInfo(data) {
      this.drawInfo = data
    },
    get(obj = {}, query = '', defaultValue = '') {
      return get(obj, query, defaultValue)
    }
  }
}
</script>
