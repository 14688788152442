<template>
  <div class="container__content">
    <div class="wrapper">
      <div class="content-pair">
        <div class="content-pair__left" style="width: 865px">
          <div class="content-1">
            <!-- 輪播 -->
            <div class="content-1__ad">
              <div class="slick">
                <vue-swiper :banners="promotionBanners" />
              </div>
            </div>
            <!-- 直播影片 -->
            <div class="content-1__films">
              <div class="filmsbox">
                <template v-for="(videoItem, index) in videoLotteries">
                  <Video
                    :key="`video-${index}`"
                    :lottery-code="videoItem.lotteryCode"
                    :lottery-type="videoItem.lotteryType"
                  />
                </template>
              </div>
            </div>
          </div>
          <!-- promo -->
          <div class="promo-banner">
            <div class="promo-banner__item">
              <div class="pb-ct" v-html="topAd"></div>
            </div>
            <div class="promo-banner__item">
              <div class="pb-ct" v-html="bottomAd"></div>
            </div>
          </div>
          <!-- menu -->
          <Menu :home-page-lotteries="homePageLotteries" />
        </div>
        <div class="content-pair__right" style="width: 320px">
          <!-- 推薦彩種-->
          <Recommend :recommend-lotteries="recommendLotteries" />
          <AnalysisTool />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSwiper from '@/components/web/pages/index/VueSwiper'
import Video from '@/components/web/pages/index/Video'
import Menu from '@/components/web/pages/index/Menu'
import Recommend from '@/components/web/pages/index/Recommend'
import AnalysisTool from '@/components/web/pages/index/AnalysisTool'
import get from 'lodash.get'
export default {
  name: 'WebHome',
  components: {
    VueSwiper,
    Video,
    Menu,
    Recommend,
    AnalysisTool
  },
  data() {
    return {
      promotionBanners: [],
      videoLotteries: [],
      homePageLotteries: {},
      recommendLotteries: [],
      topAd: '',
      bottomAd: ''
    }
  },
  computed: {
    siteInfo() {
      return this.$store.state.site.webSiteInfo
    }
  },
  async created() {
    this.promotionBanners = get(
      this,
      'siteInfo.siteSetting.promotionBanners',
      []
    )
    this.homePageLotteries = get(
      this,
      'siteInfo.siteSetting.homePageLotteries',
      {}
    )
    this.recommendLotteries = get(
      this,
      'homePageLotteries.recommendLottery',
      []
    )

    const videoLotteries = get(
      this,
      'siteInfo.siteSetting.homeVideoLotteries',
      []
    )
    // 特殊處理: 儲存在siteSetting 裡的hotLottery 並不會隨著publicCp 的刪減做過濾
    // 所以由前端處理這一手、將publicCp 和hotLottery 做交集
    const [publicCpMap] = await this.$store.dispatch(
      'lottery/getLotteryNameMapping'
    )
    const videoLotteryTypiesPromise = videoLotteries
      .filter((lotteryCode) => publicCpMap[lotteryCode])
      .map((lotteryCode) => {
        return this.$getLotteryType(lotteryCode).then((lotteryType) => ({
          lotteryCode,
          lotteryType
        }))
      })
    const videoLotteryTypiesResult = await Promise.all(
      videoLotteryTypiesPromise
    )
    this.videoLotteries = videoLotteryTypiesResult
  },
  mounted() {
    this.topAd = this.DOMdecode(this.siteInfo.siteSetting.topAd)
    this.bottomAd = this.DOMdecode(this.siteInfo.siteSetting.bottomAd)
  },
  methods: {
    DOMdecode(str) {
      const txt = new DOMParser().parseFromString(str, 'text/html')
      return txt.documentElement.textContent
    }
  }
}
</script>
