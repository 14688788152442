<template>
  <div class="filmsbox__item" @click="openVideo">
    <VideoContent
      :lottery-code="lotteryCode"
      :lottery-type="lotteryType"
    />
    <div v-if="Object.keys(drawInfo).length > 0" class="filmsbox__item__content">
      <!-- <span class="title"></span> -->
      <CounterDown :draw-info="drawInfo" :lottery-code="lotteryCode" :is-video="true" />
    </div>
  </div>
</template>

<script>
import VideoContent from '@/components/web/common/VideoContent'
import CounterDown from '@/components/web/common/CounterDown'
import { getDetailDrawInfo_desktop } from '@/api/desktop/basic'
export default {
  name: 'Video',
  components: {
    VideoContent,
    CounterDown
  },
  props: {
    lotteryCode: {
      type: String,
      required: true,
      default: () => {
        console.error('Video required key "lotteryCode"')
        return ''
      }
    },
    lotteryType: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      drawInfo: {}
    }
  },
  created() {
    this.getDetailDrawInfo(this.lotteryCode)
  },
  methods: {
    async getDetailDrawInfo(code) {
      const [data, error] = await getDetailDrawInfo_desktop(code)
      if (error) return
      this.drawInfo = data[code]
    },
    async openVideo() {
      const { lotteryCode } = this
      const [lotteryNameMapping, error] = await this.$store.dispatch('lottery/getLotteryNameMapping')
      if (error) return

      const name = lotteryNameMapping[lotteryCode]
      this.$store.commit('lottery/OPEN_VIDEO', { lotteryCode, name })
    }
  }
}
</script>
