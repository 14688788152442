<template>
  <div class="content-2">
    <div class="content-2__title">
      <span class="content-2__title__text">推荐彩种</span>
      <img class="content-2__title__img" src="@/assets/desktop/img/c2-subtitle.svg" alt="">
    </div>
    <div class="content-2__content">
      <div v-for="(item, index) in displayLotteryList" :key="index" class="content-2__cards">
        <a href="javascript:;" @click="goto(item)">
          <div class="content-2__cards__img">
            <img class="img" :src="item.data.iconURL" alt="彩种icon">
          </div>
        </a>
        <div class="content-2__cards__name">
          <span class="text">{{ item.data.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Recommend',
  props: {
    recommendLotteries: {
      type: Array,
      required: true,
      default: () => {
        console.error('Recommend required key "recommendLotteries"')
        return []
      }
    }
  },
  data() {
    return {
      displayLotteryList: []
    }
  },
  created() {
    this.getLotteryInfo()
  },
  methods: {
    async getLotteryInfo() {
      const [publicCpMap, error] = await this.$store.dispatch('lottery/getLotteryMap')
      if (error) return
      // 特殊處理: 儲存在siteSetting 裡的hotLottery 並不會隨著publicCp 的刪減做過濾
      // 所以由前端處理這一手、將publicCp 和hotLottery 做交集
      const filterLottery = this.recommendLotteries.filter((lotteryCode) => publicCpMap[lotteryCode])
      filterLottery.forEach(lottery => {
        const obj = {}
        obj.data = publicCpMap[lottery]
        obj.lotteryCode = lottery
        this.displayLotteryList.push(obj)
      })
    },
    async goto(item) {
      this.$router.push(`/lottery/trend/${item.lotteryCode}`)
    }
  }
}
</script>
