<template>
  <div v-if="routerName === 'transfer' || routerName === 'transfer-toolbox-code'">
    <div v-if="countDownTime.status" class="c9-showstyle__item__text red">{{ Timer }}</div>
    <div v-else-if="drawInfo.nextPeriod === null"></div>
    <div v-else class="c9-showstyle__item__text red">开奖中...</div>
  </div>
  <div v-else>
    <div v-if="countDownTime.status" class="timerbox">
      <div style="display: none">{{ Timer }}</div>
      <div v-if="!hideHourLabel" class="timerbox__hour">
        <span class="timerbox__hour__number" :class="{red: isVideo}">{{ countDownTime.hr }}</span>
        <span class="timerbox__hour__text">时</span>
      </div>
      <div class="timerbox__minute">
        <span class="timerbox__minute__number" :class="{red: isVideo}">{{ countDownTime.min }}</span>
        <span class="timerbox__minute__text">分</span>
      </div>
      <div class="timerbox__second">
        <span class="timerbox__second__number" :class="{red: isVideo}">{{ countDownTime.sec }}</span>
        <span class="timerbox__second__text">秒</span>
      </div>
    </div>
    <div v-else-if="drawInfo.nextPeriod === null">
      <div style="display: none">{{ Timer }}</div>
    </div>
    <div v-else class="timerbox">
      <div style="display: none">{{ Timer }}</div>
      <div class="timerbox__process">
        <span class="timerbox__process__text red">开奖中...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetailDrawInfo_desktop } from '@/api/desktop/basic'
import get from 'lodash.get'
export default {
  name: 'CounterDown',
  props: {
    drawInfo: {
      type: Object,
      required: true,
      default: () => {
        console.error('CounterDown required key "drawInfo"')
        return {}
      }
    },
    lotteryCode: {
      type: String,
      required: true,
      default: () => {
        console.error('CounterDown required key "lotteryCode"')
        return ''
      }
    },
    categoryCode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isVideo: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      countDownTime: {
        timeoutID: 0,
        hr: 0,
        min: 0,
        sec: 0,
        status: 1
      },
      newDrawInfo: {},
      routerName: this.$route.name,
      changeDataTimeoutID: 0
    }
  },
  computed: {
    Timer() {
      if (this.countDownTime.status && this.drawInfo.nextPeriod !== null) {
        this.$emit('timeChange', `${this.countDownTime.hr}:${this.countDownTime.min}:${this.countDownTime.sec}`)
        return `${this.countDownTime.hr}:${this.countDownTime.min}:${this.countDownTime.sec}`
      } else if (this.drawInfo.nextPeriod === null) {
        this.$emit('timeChange', '')
        return ''
      } else {
        this.$emit('timeChange', '开奖中...')
        return '开奖中...'
      }
    },
    hideHourLabel() {
      const lotteryMap = this.$store.state.lottery.all.lotteryMap
      const categoryCode = lotteryMap[this.lotteryCode].categoryCode

      return categoryCode === 'gaopin' || categoryCode === 'jisu'
    }
  },
  watch: {
    'drawInfo.prePeriod.code': {
      handler() {
        clearTimeout(this.countDownTime.timeoutID)
        clearTimeout(this.changeDataTimeoutID)
        this.newDrawInfo = this.drawInfo
        this.countDown()
      },
      immediate: true
    }
  },
  beforeDestroy() {
    // 結束倒數
    clearTimeout(this.countDownTime.timeoutID)
    clearTimeout(this.changeDataTimeoutID)
  },
  methods: {
    countDown() {
      if (this.newDrawInfo.nextPeriod === null) return
      const drawTime = this.newDrawInfo.nextPeriod.drawTime
      const end = Date.parse(drawTime)
      const now = Date.parse(new Date())
      const msec = end - now

      const hr = Math.floor(msec / 1000 / 60 / 60)
      const min = Math.floor(msec / 1000 / 60 % 60)
      const sec = Math.floor(msec / 1000 % 60)

      this.countDownTime.hr = hr > 9 ? hr : '0' + hr
      this.countDownTime.min = min > 9 ? min : '0' + min
      this.countDownTime.sec = sec > 9 ? sec : '0' + sec

      if (msec < 1000) {
        this.countDownTime.status = 0
        this.timeStopGetNewData()

        this.$emit('drawing', true)
        return
      }

      this.countDownTime.timeoutID = setTimeout(() => {
        this.countDown()
      }, 1000)
    },
    async timeStopGetNewData() {
      const [data, error] = await getDetailDrawInfo_desktop(this.lotteryCode)
      if (error) {
        this.changeDataTimeoutID = setTimeout(() => {
          this.timeStopGetNewData()
        }, 2000)
      }

      if (!this.newDrawInfo.prePeriod) {
        console.warn('LotteryItem timeStopGetNewData: 缺少drawInfo.prePeriod!', this.newDrawInfo.prePeriod)
        return
      }
      const previousPrePeriod = get(this.newDrawInfo, 'prePeriod.period')
      const currentPrePeriod = get(data[this.lotteryCode], 'prePeriod.period')

      // 期數沒換 重打api
      if (previousPrePeriod === currentPrePeriod) {
        this.changeDataTimeoutID = setTimeout(() => {
          this.timeStopGetNewData()
        }, 2000)
      } else {
        this.newDrawInfo = data[this.lotteryCode]
        this.countDownTime.status = 1
        this.countDown()

        this.$emit('complete', this.newDrawInfo)
        this.$emit('changeDrawInfo', this.newDrawInfo)
      }
    }
  }
}
</script>
