<template>
  <div class="content-14">
    <div class="content-14__banner fn-customed-css">
      <BannerBox
        v-for="bannerItem in bannerList"
        :key="`banner-box-${bannerItem.lotteryTitle}`"
        :lottery-title="bannerItem.lotteryTitle"
        :lottery-image="bannerItem.lotteryImage"
        :lottery-code="bannerItem.lotteryCode"
      />
    </div>
    <div class="content-14__box">
      <div class="content-14__box__title">
        <span class="c14-title">分析工具</span>
      </div>
      <div v-if="displayLotteryList.length > 0" class="content-14__box__content">
        <AnalysisCard
          v-for="lottery in displayLotteryList"
          :key="lottery.lotteryCode"
          :lottery="lottery"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BannerBox from '@/components/web/pages/index/Analysis/BannerBox'
import AnalysisCard from '@/components/web/pages/index/Analysis/AnalysisCard'
export default {
  name: 'AnalysisTool',
  components: {
    BannerBox,
    AnalysisCard
  },
  data() {
    return {
      bannerList: [
        {
          lotteryTitle: 'PK拾',
          lotteryImage: require('@/assets/desktop/img/banner-pk10.jpg'),
          lotteryCode: 'jspk10'
        },
        {
          lotteryTitle: '时时彩',
          lotteryImage: require('@/assets/desktop/img/banner-ssc.jpg'),
          lotteryCode: 'jsssc'
        },
        {
          lotteryTitle: '快3',
          lotteryImage: require('@/assets/desktop/img/banner-k3.jpg'),
          lotteryCode: 'jisuk3'
        },
        {
          lotteryTitle: '11选5',
          lotteryImage: require('@/assets/desktop/img/banner-11x5.jpg'),
          lotteryCode: 'sh11x5'
        }
      ],
      defaultLotteryCodes: ['jspk10', 'jsssc', 'jisuk3', 'sh11x5'],
      displayLotteryList: []
    }
  },
  async created() {
    this.displayLotteryList = await this.getDisplayItem(this.defaultLotteryList)
  },
  methods: {
    async getDisplayItem(selectedlotterylist = []) {
      const [publicCpMap, error] = await this.$store.dispatch('lottery/getLotteryMap')
      if (error) return
      /*
        特殊處理:
        預設顯示(前端寫死)四個彩種: jspk10, jsssc, jisuk3, sh11x5
        但是需符合業務邏輯: 判断該彩種當下是不是有被後台或是後端關閉
        因此需將預設彩種與後端提供的全彩種做交集過濾
      */
      const selectedLotteryCodes = this.defaultLotteryCodes.filter((lotteryCode) => publicCpMap[lotteryCode])
      return selectedLotteryCodes.map(lotteryCode => {
        const obj = {}
        obj.lotteryCode = lotteryCode
        obj.data = publicCpMap[lotteryCode]
        return obj
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fn-customed-css {
  flex-wrap: wrap;
  margin-bottom: 0;
}
</style>
